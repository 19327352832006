@import 'font/font.scss';
@import 'grid/grid';
@import 'colors/colors';
@import 'typography/typography';
@import 'animation/keyframes';
@import 'components/buttons.scss';


// components  
// @import 'components/components';

// helpers
.legacy-page .CardsSection.abb-section-padding > .container {
    display: none;
    }
    .legacy-page .CardsSection.abb-section-padding {
    padding-top: 8px !important;
    }
    .goog-te-banner-frame{
        display: none;
    }
    body{
        top: 0 !important;
    }
    #google_translate_element{
        margin-right: 16px;
    }
    .goog-te-gadget{
        max-height: 42px;
        overflow: hidden;
    }
    .goog-te-gadget .goog-te-combo {
        margin: 0 !important;
        padding: 11px;
        background-color: transparent;
        outline: 0;
        border: 1px solid #a9a9a9;
    }
    .skiptranslate.goog-te-gadget > div + span
.addgreybg{
    background-color:#f0f0f0;
 color:#000;
 }
 .addlightgrey{
 background-color:#fafafa
 }
.pfu-section-padding{
    padding-top: 54px;
    padding-bottom: 40px;
    
    @include desktop{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
.pfu-section-header {
    margin: 0;
    margin-bottom: 40px;
}
.almost-white-bg{
    background-color: color(AlmostWhite);
}
.white-bg{
    background-color: color(WhiteColor);
}
.greyBG{
    background-color: color(GreyBg);
}
button:focus{
    outline: none;
}
.no-space{
    white-space: nowrap;
}

.pos-relative {
    position: relative;
}
.mw-100 { max-width: 100% !important; }
.mh-100 { max-height: 100% !important; }

.img-fluid {
    max-width: 100%;
    height: auto;
}



p:last-child {
    margin-bottom: 0;
}
// Viewport additional helpers

.min-vw-100 { min-width: 100vw !important; }
.min-vh-100 { min-height: 100vh !important; }

.vw-100 { width: 100vw !important; }
.vh-100 { height: 100vh !important; }

.h-100 {height:100%;}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

// widths
.w-100 { width: 100%; }

.w-50 { width:50% }

@include tablet {
    .w-md-100 { width: 100%; }

    .w-md-50 { width:50% }
}

@include desktop {
    .w-xl-100 { width: 100%; }

    .w-xl-50 { width:50% }
}

// Margins
.mx-neg-15 { margin-right:-15px; margin-left:-15px; }

.mt-0 {
    margin-top: 0px !important;
}
.mb-0 {
    margin-bottom: 0px !important;
}

.mt-8 {
    margin-top: 8px !important;
}
.mb-8 {
    margin-bottom: 8px !important;
}

.mt-16 {
    margin-top: 16px !important;
}
.mb-16 {
    margin-bottom: 16px !important;
}

.mt-24 {
    margin-top: 24px !important;
}
.mb-24 {
    margin-bottom: 24px !important;
}

.mt-32 {
    margin-top: 32px !important;
}
.mb-32 {
    margin-bottom: 32px !important;
}

.mt-40 {
    margin-top: 40px !important;
}
.mb-40 {
    margin-bottom: 40px !important;
}
.mt-56{
    margin-top:56px !important;
}
.mb-56{
    margin-bottom:56px !important;
}
.mt-64 {
    margin-top: 64px !important;
}
.mb-64 {
    margin-bottom: 64px !important;
}

@include tablet{
    
    .mx-neg-md-15 { 
        margin-right:-15px; 
        margin-left:-15px;
    }

    .mx-md-15 { 
        margin-right: 15px; 
        margin-left: 15px;
    }

    .mt-md-0 {
        margin-top: 0px !important;
    }
    .mb-md-0 {
        margin-bottom: 0px !important;
    }
    
    .mt-md-16 {
        margin-top: 16px !important;
    }
    .mb-md-16 {
        margin-bottom: 16px !important;
    }
    
    .mt-md-24 {
        margin-top: 24px !important;
    }
    .mb-md-24 {
        margin-bottom: 24px !important;
    }
    
    .mt-md-32 {
        margin-top: 32px !important;
    }
    .mb-md-32 {
        margin-bottom: 32px !important;
    }
    
    .mt-md-40 {
        margin-top: 40px !important;
    }
    .mb-md-40 {
        margin-bottom: 40px !important;
    }
    .mt-md-56{
        margin-top:56px !important;
    }
    .mb-md-56{
        margin-bottom:56px !important;
    }
    .mt-md-64 {
        margin-top: 64px !important;
    }
    .mb-md-64 {
        margin-bottom: 64px !important;
    }
}

@include desktop{

    .mx-neg-xl-15 { 
        margin-right:-15px; 
        margin-left:-15px;
    }

    .mx-xl-15 { 
        margin-right: 15px; 
        margin-left: 15px;
    }
    
    .mt-xl-0 {
        margin-top: 0px !important;
    }
    .mb-xl-0 {
        margin-bottom: 0px !important;
    }
    
    .mt-xl-16 {
        margin-top: 16px !important;
    }
    .mb-xl-16 {
        margin-bottom: 16px !important;
    }
    
    .mt-xl-24 {
        margin-top: 24px !important;
    }
    .mb-xl-24 {
        margin-bottom: 24px !important;
    }
    
    .mt-xl-32 {
        margin-top: 32px !important;
    }
    .mb-xl-32 {
        margin-bottom: 32px !important;
    }
    
    .mt-xl-40 {
        margin-top: 40px !important;
    }
    .mb-xl-40 {
        margin-bottom: 40px !important;
    }
    .mt-xl-56{
        margin-top:56px !important;
    }
    .mb-xl-56{
        margin-bottom:56px !important;
    }
    .mt-xl-64 {
        margin-top: 64px !important;
    }
    .mb-xl-64 {
        margin-bottom: 64px !important;
    }
   
}


// Paddings
.pt-0 {
    padding-top: 0px !important;
}
.pb-0 {
    padding-bottom: 0px !important;
}
.pt-16 {
    padding-top: 16px !important;
}
.pb-16 {
    padding-bottom: 16px !important;
}

.pt-24 {
    padding-top: 24px !important;
}
.pb-24 {
    padding-bottom: 24px !important;
}

.pt-32 {
    padding-top: 32px !important;
}
.pb-32 {
    padding-bottom: 32px !important;
}

.pt-40 {
    padding-top: 40px !important;
}
.pb-40 {
    padding-bottom: 40px !important;
}

.pt-64 {
    padding-top: 64px !important;
}
.pb-64 {
    padding-bottom: 64px !important;
}

@include tablet{
    .pt-md-0 {
        padding-top: 0px !important;
    }
    .pb-md-0 {
        padding-bottom: 0px !important;
    }
    .pt-md-16 {
        padding-top: 16px !important;
    }
    .pb-md-16 {
        padding-bottom: 16px !important;
    }
    
    .pt-md-24 {
        padding-top: 24px !important;
    }
    .pb-md-24 {
        padding-bottom: 24px !important;
    }
    
    .pt-md-32 {
        padding-top: 32px !important;
    }
    .pb-md-32 {
        padding-bottom: 32px !important;
    }
    
    .pt-md-40 {
        padding-top: 40px !important;
    }
    .pb-md-40 {
        padding-bottom: 40px !important;
    }
    
    .pt-md-64 {
        padding-top: 64px !important;
    }
    .pb-md-64 {
        padding-bottom: 64px !important;
    }
}

@include desktop{
    .pt-xl-0 {
        padding-top: 0px !important;
    }
    .pb-xl-0 {
        padding-bottom: 0px !important;
    }
    .pt-xl-16 {
        padding-top: 16px !important;
    }
    .pb-xl-16 {
        padding-bottom: 16px !important;
    }
    
    .pt-xl-24 {
        padding-top: 24px !important;
    }
    .pb-xl-24 {
        padding-bottom: 24px !important;
    }
    
    .pt-xl-32 {
        padding-top: 32px !important;
    }
    .pb-xl-32 {
        padding-bottom: 32px !important;
    }
    
    .pt-xl-40 {
        padding-top: 40px !important;
    }
    .pb-xl-40 {
        padding-bottom: 40px !important;
    }
    
    .pt-xl-64 {
        padding-top: 64px !important;
    }
    .pb-xl-64 {
        padding-bottom: 64px !important;
    }
}

// Text alignment
.text-center {
    text-align: center !important;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-lowercase {
    text-transform: lowercase;
}
.text-capitalize {
    text-transform: capitalize;
}
.table-head{
    @include fontStyle(22,28px,700);
    @include tablet{
        font-size: 32px;
        line-height: 40px;
    }
}
.table-wrapper{
    overflow-x: scroll;
  
    @include desktop{
        overflow-x: auto;
    }
}
.TextLink{
    border: 0;
    outline: none;
    display: inline-block;
    margin-top: auto;
    text-decoration: none;
    @include fontStyle(14,18px,400);
    align-items: center;
    display: inline-flex;
    &::after{
        margin-left: 8px;
         border: 2px solid color('BlackColor');
         border-width: 0 2px 2px 0;
         display: inline-block;
         padding: 2px;
      content: "";
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    }
tbody{
    tr:nth-child(2n+1){
        background: color(LightestGrey);
    }
}
table{
    width: 100%;
}
th{
    background-color: color(AlmostWhite);
    @include fontStyle(16,20px,700);
}
td,th{
    padding: 16px;
    border: 1px solid color(MediumGrey);

}
// grid columns 
.grid-layout {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    .container,.row, .bg {
        height: 100%;
    }
    .col {
        height: 100%;
        .bg {
            background: pink;
            opacity: 0.2;
        }
    }
}
/***********Select*************/

.select-wrapper-trans{
    /* The container must be positioned relative: */


.form-control{
    border: 1px solid #A9A9A9 ;
}
 select {
    display: none;
}
.empty {
    color:#a9a9a9 !important;
}
.select-selected {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 151px;
    position: relative;
}

    /* Style the arrow inside the select element: */
    .select-selected:after {
        position: absolute;
        content: "";
        top: 15px;
        right: 18px;
        transition: all ease 300ms;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        border: solid #A9A9A9;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;

    }

&::after {
    display: none !important;
}
.select-wrapper {
    background: #fff;
}
.select-arrow-active {
    
border-bottom: 0;
}
/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    top: 18px;
    transition: all ease 300ms;
}

/* style the items (options), including the selected item: */
.select-items .item, .select-selected {
 
    padding: 0 0 16px 0;
    cursor: pointer;
}

.select-selected {
    padding:11px 8px;

}
@media (max-width:767px){
    .select-selected{
        padding-right: 26px;
    }
}
.select-items .item:last-of-type{
    padding-bottom:16px;
}
/* form .select-selected.select-arrow-active{
    padding-bottom: 18.8px !important;
} */
/* Style items (options): */
.select-items {
    position: absolute;
    background-color: #fff;
    top: 90%;
    left: 0;
    right: 0;
    z-index: 99;
    border: 1px solid #A9A9A9;

width: 151px;
    max-height: 352px;
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    overflow-y: auto;
}
.select-items > div{
    padding: 8px;
    font-size: 16px;
line-height: 20px;
cursor: pointer;
white-space: pre-line;
/* Primary/Black */
color: #000000;
    &:hover{
        background-color: #F0F0F0;
    }
}
/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

.select-items div:hover,
.select-items div.same-as-hover {
  background-color: #f0f0f0;
}

.same-as-selected {
    background-color: #f0f0f0;
  
}
.goog-te-combo{
    display: none;
}
.same-as-selected::before {
    background-image: url('/sites/default/files/fa-check.png');
    width: 13.2px;
    height: 10.1px;
    background-repeat: no-repeat;
    content: "";
    display: inline-block;
    background-size: 100% auto;
    margin-left: -18.5px;
    margin-right: 5.3px;
}

.select-items.mCustomScrollbar {
    max-height:160px;
}

    
}
.mt-30{
    margin-top: 30px;
} .desk-mt-36{
    @include lg-screen{
        margin-top: 36px;
    }

}
.mb-55{
    margin-bottom: 55px;
}
.mt-75{
    margin-top: 75px;
}
.mt-36{
    margin-top: 36px;
}