
// a,a:hover{
//     color: color(BlackColor);
// }
@mixin fontStyle($size, $lineHeight, $weight, $family: inherit, $letter-spacing: normal) {
    font-size: $size + px;
    line-height: $lineHeight + px;
    font-weight: $weight;
    font-family: $family;
    letter-spacing: $letter-spacing + px;
}

body {
    @include fontStyle(16, 24, 400, 'open sans', 0.16);;
    color: color(BlackColor);
}

.white {
    color:color(WhiteColor);
}

b,
strong {
    font-weight: 900;
}

sup {
    font-size:65%;
}

.h1-bold {
    @include fontStyle(42, 52, 900, 'open sans', 0.42);
}

%h1-bold {
    @include fontStyle(42, 52, 900, 'open sans', 0.42);
}

.h1 {
    @include fontStyle(42, 52, 400, 'open sans', 0.42);
}

%h1 {
    @include fontStyle(42, 52, 400, 'open sans', 0.42);
}

.h2-bold {
    @include fontStyle(36, 40, 900, 'open sans', 0.36);
}

%h2-bold {
    @include fontStyle(36, 40, 900, 'open sans', 0.36);
}

.h2 {
    @include fontStyle(36, 40, 400, 'open sans', 0.36);
}

.h3-bold {
    @include fontStyle(26, 30, 900, 'open sans', 0.26);
}

%h3-bold {
    @include fontStyle(26, 30, 900, 'open sans', 0.26);
}

.h3 {
    @include fontStyle(26, 30, 400, 'open sans', 0.26);
}

%h3 {
    @include fontStyle(26, 30, 400, 'open sans', 0.26);
}

.h4-bold {
    @include fontStyle(22, 30, 900, 'open sans', 0.22);
}

%h4-bold {
    @include fontStyle(22, 30, 900, 'open sans', 0.22);
}

.h4 {
    @include fontStyle(22, 30, 600, 'open sans', 0.22);
}

%h4 {
    @include fontStyle(22, 30, 600, 'open sans', 0.22);
}

.p-bold {
    @include fontStyle(16, 24, 900, 'open sans', 0.16);
}

%p-bold {
    @include fontStyle(16, 24, 900, 'open sans', 0.16);
}

.p {
    @include fontStyle(16, 24, 400, 'open sans', 0.16);
}

%p {
    @include fontStyle(16, 24, 400, 'open sans', 0.16);
}
.small {
    font-family: 'open sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.14px;
    
    }
%small {
        font-family: 'open sans';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.14px;
        
        }

.Roboto-Bold-18px {
     @include fontStyle( 18, 36, 900, 'Roboto', 0);
}
%Roboto-Bold-18px {
    @include fontStyle( 18, 36, 900, 'Roboto', 0);
}
.Roboto-Bold-24px {
    @include fontStyle( 24, 36, 900, 'Roboto', 0);
}
%Roboto-Bold-24px {
    @include fontStyle( 24, 36, 900, 'Roboto', 0);
}
.OpenSans-Regular-16px{
     @include fontStyle(16, 24, 400, 'open sans', 0.1);
}
%OpenSans-Regular-16px{
    @include fontStyle(16, 24, 400, 'open sans', 0.1);
}

.OpenSans-Bold-42px{
    @include fontStyle(42, 52, 900, 'open sans', 0.1);
}
%OpenSans-Bold-42px{
    @include fontStyle(42, 52, 900, 'open sans', 0.1);
}
.OpenSans-Regular-36px-46{
    @include fontStyle(36, 46, 400, 'open sans', 0.1);
}
%OpenSans-Regular-36px-46{
    @include fontStyle(36, 46, 400, 'open sans', 0.1);
}
.OpenSans-Regular-36px-40{
    @include fontStyle(36, 40, 400, 'open sans', 0.1);
}
%OpenSans-Regular-36px-40{
    @include fontStyle(36, 40, 400, 'open sans', 0.1);
}
.OpenSans-Regular-26px{
    @include fontStyle(26, 30, 400, 'open sans', 0.1);
}
%OpenSans-Regular-26px{
    @include fontStyle(26, 30, 400, 'open sans', 0.1);
}
.OpenSans-SemiBold-22px{
    @include fontStyle(22, 30, 600, 'open sans', 0.1);
}
%OpenSans-SemiBold-22px{
    @include fontStyle(22, 30, 600, 'open sans', 0.1);
}
.OpenSans-SemiBold-20px{
    @include fontStyle(20, 24, 600, 'open sans', 0.2);
}
%OpenSans-SemiBold-20px{
    @include fontStyle(20, 24, 600, 'open sans', 0.2);
}
.OpenSans-Regular-20px{
    @include fontStyle(20, 24, 400, 'open sans', 0.2);
}
%OpenSans-Regular-20px{
    @include fontStyle(20, 24, 400, 'open sans', 0.2);
}
.Gotham-Bold-80px{
    @include fontStyle(80, 50, 900, 'Gotham-Bold', 0.1);
}
%Gotham-Bold-80px{
    @include fontStyle(80, 50, 900, 'Gotham-Bold', 0.1);
}
.Gotham-Bold-18px{
    @include fontStyle(18, 20, 900, 'Gotham-Bold', 0.18);
}
%Gotham-Bold-18px{
    @include fontStyle(18, 20, 900, 'Gotham-Bold', 0.18);
}
.Gotham-Medium-16px{
    @include fontStyle(16, 26, 600, 'Gotham-Medium', 0.1);
}
%Gotham-Medium-16px{
    @include fontStyle(16, 26, 600, 'Gotham-Medium', 0.1);
}
.Gotham-Book-16px{
    @include fontStyle(36, 40, 400, 'Gotham-Book', 0.1);
}
%Gotham-Book-16px{
    @include fontStyle(36, 40, 400, 'Gotham-Book', 0.1);
}




p,ul {
    margin-top: 0px;
  margin-bottom: 0;
   

    
    // @include fontStyle(16, 24, 400);

    // @include desktop {
    //     @include fontStyle(18, 32, 400);
    // }

}
// li{
//     @include fontStyle(16, 20, 300, 'open sans');
// }
p:last-child{
    margin-bottom: 0px;
}




// @include tablet {
//     .headline-28 {
//      @include fontStyle(56, 56, 400);
//     }

//     .headline-28-bold {
//         @include fontStyle(56, 56, 900);
//     }
// }