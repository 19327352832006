@import '../bootstrap/bootstrap-grid.scss';
@import '../bootstrap/forms';
$grid-breakpoints: (
  xs: 0,
  md: 768px,
  lg:992px,
  xl: 1024px
);

$container-max-widths: (
  xl: 1392px
);

$grid-gutter-width: 24px;
$mobile-grid-breakpoints: (
    xs: 0,
);
$mobile-grid-gutter-width: 16px;
$mobile-grid-columns: 4;

@import '../bootstrap/grid';

$tablet: 768px;
$desktop: 1024px;

@mixin tablet {
    @media only screen and (min-width: #{$tablet}) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: #{$desktop}) {
        @content;
    }
}

@mixin tabletOnly {
    @media only screen and (min-width: #{$tablet}) and (max-width: 1023px) {
        @content;
    }
}

.container {
    padding-left: 24px;
    padding-right: 24px;
}

@media (max-width: 767px) {
    .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12,
     .col-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, 
     .col-md-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12,
      .col-xl-auto,.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12
      {
        padding-right: 8px;
        padding-left: 8px;
    }
    .container {
        padding-left: 16px;
        padding-right: 16px;
    }
    .row {
        margin-left: -8px;
        margin-right: -8px;
    }
}
.grid-layout {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    .container,.row, .bg {
        height: 100%;
    }
    .col {
        height: 100%;
        .bg {
            background: pink;
            opacity: 0.2;
        }
    }
}