$grid-breakpoints: (
  xs: 0,
  md: 768px,
  xl: 1024px
);

$container-max-widths: (
  xl: 1392px
);

$grid-gutter-width: 24px;
$mobile-grid-breakpoints: (
    xs: 0,
);
$mobile-grid-gutter-width: 16px;
$mobile-grid-columns: 4;


$tablet: 768px;
$desktop: 1024px;

@mixin md-screen {
    @media only screen and (min-width: #{$tablet}) {
        @content;
    }
}

@mixin lg-screen {
    @media only screen and (min-width: #{$desktop}) {
        @content;
    }
}

@mixin tabletOnly {
    @media only screen and (min-width: #{$tablet}) and (max-width: 1023px) {
        @content;
    }
}
