$colors: (
    WhiteColor:#ffffff,
    BlueColor:#163182,
    BlackColor:#000000,
    purpleColor:#622B73,
    GreyColor:#0000004D,
    AlmostWhite: #FAF8F9,
    MediumGrey:#00000033,
    DarkGrey:#212529,
    LighterGrey: #E3E3E3,
    LightGrey:#00000029,
    LightBlue:#044DA1,
    DarkBue:#000F48,
    LightPurple:#6C2E77,
    DarkGrey04:RGB(33, 37, 41, 0.4),
    
    GreyBackground:#F2F2F2,
    DarkMediumGrey:#00000099,
    GreyBg:#F2F2F2,
  
);

@function color($color-name) {
    @return map-get($colors, $color-name)
};

.white {
    color: color(White);
}
.blue-color {
    color: color(BlueColor);
}
.white-bg{
    background-color: color(WhiteColor);
}
.greyBG{
    background-color: color(GreyBg);
}
.darkblueText{
color:color(DarkBue);
}

// Shadow
$shallow: 0 1px 4px 0 rgba(26,25,25,0.15);
$deep: 0 2px 10px 0 rgba(26,25,25,0.15);
$hover: 0 4px 20px 0 rgba(26,25,25,0.15);

@mixin shadow($name) {
    box-shadow: $name;
}

// borders / strokes
$WhiteStroke: 1px solid color(RedColor);
