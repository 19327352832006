@import '../grid/_gird';
@import '../typography/typography.scss';
@import '../colors/colors.scss';

button{
    cursor: pointer;
}
.global-btn{
    display: inline-block;
    @extend %Roboto-Bold-18px;
    padding: 0 20px;
    line-height: 30px;
    text-decoration: none;
    transition: all ease 300ms;
    text-align: center;
    &.round-btn{
        border-radius: 40px;
    }
    i{
        margin-right: 9px;
    }
    &:hover{
        text-decoration: none;
    }
}
.purple-btn-fill{
    color: color(WhiteColor);
    background-color: color(purpleColor) !important;
    border: 0px;
    &:hover{
        color: color(WhiteColor);
        background-color: color(RedColor) !important;
        &.icon-btn{
            i{
                transition: all ease 300ms;
                color: color(WhiteColor);
            }
        }
    }
    &.icon-btn{
        i{
            color: color(WhiteColor);
        }
    }
}
