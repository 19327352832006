@font-face {
    font-family: 'Gotham-Book';
    src: url('../fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham-Medium';
    src: url('../fonts/Gotham-Medium.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Gotham-Bold';
    src: url('../fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ABBvoice';
    src: url('../fonts/ABBvoice-Light.woff2') format('woff2'),
        url('../fonts/ABBvoice-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'open sans';
    src: url('../fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'open sans';
    src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'open sans';
    src: url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../fonts/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'open sans';
    src: url('../fonts/OpenSans-ExtraBold.woff2') format('woff2'),
        url('../fonts/OpenSans-ExtraBold.woff') format('woff');
    font-weight:800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'open sans';
    src: url('../fonts/OpenSans-BoldItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'open sans';
    src: url('../fonts/OpenSans-Italic.woff2') format('woff2'),
        url('../fonts/OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'open sans';
    src: url('../fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'open sans';
    src: url('../fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-ExtraBoldItalic.woff') format('woff');
    font-weight:800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff');
    font-weight:800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff');
    font-weight:500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff');
    font-weight:bold;
    font-style: normal;
    font-display: swap;
}